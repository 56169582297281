import { useFetch } from 'use-http';
import { Link } from 'react-router-dom';

export const HomePage = () => {
    const { loading: userInfoLoading, data: userInfoData, response: userInfoResp } = useFetch(`${process.env.REACT_APP_PEER_REVIEW_API_URL}/user`, { credentials: "include" }, [])
    const { loading: curEventLoading, data: curEventData = "" } = useFetch(`${process.env.REACT_APP_PEER_REVIEW_API_URL}/currentEvent`, { credentials: "include" }, [])


    return (
        <div>
            {!curEventLoading && !userInfoLoading && userInfoResp.ok ? <div>
                <p>user id: {userInfoData.id}</p>
                <p>current event: {curEventData}</p>
                <Link to="/emails"><button className='btn btn-primary'>Manage email addresses</button></Link>
                <Link to="emailContent"><button className='btn btn-primary'>Manage email content</button></Link>
            </div>
                : <div>
                    <p>Not logged in</p>
                    <a href={`${process.env.REACT_APP_PEER_REVIEW_API_URL}/googleAuth`}>Connect Google Calendar</a>
                </div>}

        </div>
    )
}
