import { useFetch } from "use-http"

export const EmailContentPage = () => {
    const { data } = useFetch(`${process.env.REACT_APP_PEER_REVIEW_API_URL}/emailContent`, { credentials: "include" }, [])

    const handleUpdateEmailContent = async (e) => {
        e.preventDefault();
        let res = await fetch(`${process.env.REACT_APP_PEER_REVIEW_API_URL}/emailContent`, {
            method: 'POST',
            credentials: 'include',
            body: e.target[0].value
        })

        if (res.ok) {
            window.location.reload()
        } else alert("Failed to update email content.")
    }

    return <div>
        <p>
            The following message will be appended to the email notification.
        </p>

        <form onSubmit={handleUpdateEmailContent}>
            <label>
                Email Content
                <textarea defaultValue={data} rows={10} cols={40} wrap="off" required style={{ resize: "none" }} />
            </label>
            <button type="submit" className="btn btn-primary mb-2">update</button>
        </form>


        <p>
            Example email body preview:

            <div style={{ border: "2px solid black" }}>
                <code>
                    Case number 1234567890 was selected for peer review.
                    <br />
                    {data}
                </code>
            </div>


        </p>
    </div>
}