import { createBrowserRouter } from "react-router-dom";
import { HomePage } from "./pages/home";
import { GoogleCallback } from "./pages/googleCallback";
import { EmailsPage } from "./pages/emails";
import { EmailContentPage } from "./pages/emailContent";

export const router = createBrowserRouter([
    {
        path: "/login",
        element: <HomePage/>
    },
    {
        path: "/",
        element: <HomePage/>
    },
    {
        path: "/emails",
        element: <EmailsPage/>
    },
    {
        path: "/emailContent",
        element: <EmailContentPage/>
    },
    {
        path: "/googleCallback",
        element: <GoogleCallback/>
    }
]);