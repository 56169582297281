import { useFetch } from "use-http"

export const EmailsPage = () => {
    const { data = {} } = useFetch(`${process.env.REACT_APP_PEER_REVIEW_API_URL}/emailMap`, { credentials: "include" }, [])
    const { data: gCalEvents = {} } = useFetch(`${process.env.REACT_APP_PEER_REVIEW_API_URL}/googleCalEventsList`, { credentials: "include" }, [])

    const updateEmail = async (event) => {
        event.preventDefault()
        let res = await fetch(`${process.env.REACT_APP_PEER_REVIEW_API_URL}/emailMap`, {
            method: "POST",
            credentials: "include",
            body: `{"${event.target[0].value}": "${event.target[1].value}"}`
        })
        if (res.status > 300) {
            alert("error")
        }
        window.location.reload()
    }

    const deleteEmail = async (event) => {
        let res = await fetch(`${process.env.REACT_APP_PEER_REVIEW_API_URL}/emailMap/email`, {
            method: "DELETE",
            credentials: "include",
            body: `["${event.target.value}"]`
        })

        if (res.status > 300) {
            alert("error")
        }
        window.location.reload()
    }

    console.log("gcal events: ", Object.entries(gCalEvents))

    if (data !== undefined) return (<div className="container">
        <div className="row">
            <div className="col-md-5 border border-primary">
                <p>Saved emails</p>
                <table className="table">
                    <thead><tr>
                        <th>name</th>
                        <th>email</th>
                    </tr></thead>
                    <tbody>
                        {Object.entries(data).map(e => <tr key={e[0]}>
                            <td>{e[0]}</td>
                            <td>
                                <form class="form-inline" onSubmit={updateEmail}>
                                    <input type="hidden" value={e[0]} />
                                    <div class="form-group mb-2"><input type="email" className="form-control" defaultValue={e[1]} size={50}/></div>
                                    <button type="submit" className="btn btn-primary mb-2">update</button>
                                </form>
                            </td>
                            <td>
                                <button className="btn btn-danger" value={e[0]} onClick={deleteEmail}>delete</button>
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>

            <div className="col-md-5 border border-primary">
                <p>Add From Upcoming Google Calendar Events</p>
                <table className="table">
                    <thead><tr>
                        <th>name</th>
                        <th>email</th>
                    </tr></thead>
                    <tbody>
                        {Object.entries(gCalEvents).map(e => e[1] !== "" ? <></> : <tr key={e[0]}>
                            <td>{e[0]}</td>
                            <td>
                                <form onSubmit={updateEmail}>
                                    <input type="hidden" value={e[0]} />
                                    <div class="form-group mb-2"><input type="email" className="form-control" placeholder="example@email.com" size={50}/></div>
                                    <button type="submit" className="btn btn-primary">add</button>
                                </form>
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>

            <div className="col-sm-2 border border-primary">
                <div className="container-fluid">
                    <p>Add new user</p>
                    <form onSubmit={updateEmail}>
                        <div class="form-group"><label>Name<input className="form-control"/></label></div>
                        <div class="form-group"><label>Email<input type="email" className="form-control" placeholder="example@email.com" size={50}/></label></div>
                        <button className="btn btn-primary" type="submit">add</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
    )

    return <></>
}